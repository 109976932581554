







































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import controller from '@/app/ui/controllers/POSController'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import DownloadIcon from '@/app/ui/assets/download_icon.vue'
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import CheckBoxUncheckedIcon from '@/app/ui/assets/check_box_unchecked.vue'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'
import { POSBulkResponse } from '@/domain/entities/POS'
import { POS_PAGINATION } from '@/app/infrastructures/misc/Constants'

const MAX_SIZE = 5242880

@Component({
  components: {
    Button,
    TextInput,
    DropdownSelect,
    PaginationNav,
    Loading,
    DownloadIcon,
    DataTable,
    Modal,
    CheckBoxCheckedIcon,
    CheckBoxUncheckedIcon,
    ExpandIcon,
  },
})
export default class POS extends Vue {
  isGuest = Utils.isGuest()
  acceptedFileFormat =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
  controller = controller
  totalRows = 0
  failedRows = 0
  failedMessages = ['']
  failedRowsPOSModal = false
  showStatusSelection = false
  updatePOSModal = false
  updateFavoriteModal = false
  uploadUpdatePOSConfirmationModal = false
  uploadRegFavConfirmationModal = false
  isExcelFileValid = false
  delayedTask = 0
  file = new File([], '')
  sortOptions = [
    { label: 'A-Z', value: 'ASC' },
    { label: 'Z-A', value: 'DESC' },
  ]
  statusOptions = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Suspended', value: 'SUSPENDED' },
  ]
  subscriptionOptions = [
    { label: 'Reguler', value: 'REGULER' },
    { label: 'Favorite', value: 'FAVORITE' },
  ]
  parameters = {
    page: 1,
    perPage: POS_PAGINATION,
    search: null,
    sortBy: this.sortOptions[0],
    filterStatus: [{ label: '', value: '' } as Record<string, unknown>],
    filterSubscription: [{ label: '', value: '' } as Record<string, unknown>],
    filterSubdistrict: [] as Record<string, any>,
  }
  searchSubdistrict = ''
  headers = [
    'POS ID',
    'Nama POS',
    'Alamat',
    'Kecamatan / Kota',
    'No HP POS',
    'Subscription',
    'Status POS',
  ]

  created() {
    document.addEventListener('click', this.closeStatusSelection)
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: Utils.alwaysNumber(queries.perPage) || POS_PAGINATION,
        search: queries.search,
        sortBy:
          this.sortOptions.find((item) => item.value === queries.sortBy) ||
          this.sortOptions[0],
        filterStatus:
          this.statusOptions.filter((item) =>
            this.commaToArray(queries.filterStatus).includes(item.value)
          ) || [],
        filterSubscription:
          this.subscriptionOptions.filter((item) =>
            this.commaToArray(queries.filterSubscription).includes(item.value)
          ) || [],
        filterSubdistrict: queries.filter
          ? this.filterSubdistrictOptions.find(
              (item) => item.value === queries.filter
            ) || []
          : [],
      }
    }

    EventBus.$on(
      EventBusConstants.UPLOAD_BULK_UPDATE_POST_SUCCESS,
      (instance: POSBulkResponse) => {
        this.fetchPOSData(true)

        this.totalRows = instance.totalRows || 0
        this.failedRows = instance.failedRows || 0
        this.failedMessages = instance.failedMessages || []

        this.updatePOSModal = false
        this.updateFavoriteModal = false
        this.uploadUpdatePOSConfirmationModal = false
        this.uploadRegFavConfirmationModal = false

        if (this.failedMessages.length > 0) {
          this.failedRowsPOSModal = true
        }
      }
    )

    EventBus.$on(
      EventBusConstants.DOWNLOAD_POS_READY,
      (instance: { downloadUrl: string }) => {
        const link = document.createElement('a')
        link.href = instance.downloadUrl
        link.setAttribute('download', 'file.xlsx') //or any other extension
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    )

    this.fetchPOSData()
    this.fetchDistrictData()
  }

  beforeDestroy() {
    document.removeEventListener('click', this.closeStatusSelection)
    EventBus.$off(EventBusConstants.UPLOAD_BULK_UPDATE_POST_SUCCESS)
    EventBus.$off(EventBusConstants.DOWNLOAD_POS_READY)
  }

  get filterSubdistrictOptions(): Record<string, unknown>[] {
    return controller.subdistricts.map((item) =>
      Object.assign({}, { label: item.name, value: item.name })
    )
  }

  get params() {
    return {
      ...this.parameters,
      sortBy: this.parameters.sortBy.value,
      filterStatus: this.arrayToComma(this.parameters.filterStatus),
      filterSubdistrict: this.parameters.filterSubdistrict
        ? this.parameters.filterSubdistrict.value
        : '',
      filterSubscription: this.arrayToComma(this.parameters.filterSubscription),
    }
  }

  get attachmentName() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.file ? this.file.name! : null
  }

  get posDataTable() {
    return controller.posData.map((pos) => [
      pos.id,
      pos.name,
      pos.address,
      pos.subDistrictName,
      pos.phone,
      pos.subscriptionStatus ? 'Favorit' : 'Reguler',
      pos.status,
    ])
  }

  @Watch('parameters.search')
  onKeywordChanged(val: string) {
    val === '' && this.fetchPOSData(true)
  }

  @Watch('parameters.filterSubdistrict')
  onFilterSubdistrictChanged(val: Record<string, never>) {
    if (!val) {
      this.parameters.filterSubdistrict = []
    }
  }

  @Watch('params')
  onParamsChanged(val: Record<string, never>) {
    this.$router.push({
      query: { ...val },
    })
  }

  private closeStatusSelection(e: MouseEvent) {
    const element = this.$refs.elStatusDropdown as Element
    if (!element.contains(e.target as Node)) {
      this.showStatusSelection = false
    }
  }

  private fetchPOSData(resetPage = false) {
    if (resetPage) {
      this.parameters.page = 1
    }
    controller.getPOSData(this.params)
  }

  private getExportUrl() {
    controller.getExportedFile(this.params)
  }

  private fetchDistrictData() {
    controller.findSubdistrict({ keyword: this.searchSubdistrict })
  }

  private onSelectSubdistrictRemoved() {
    this.parameters.filterSubdistrict = []
    this.fetchPOSData(true)
  }

  private onSearchSubdistrict(query: string) {
    this.searchSubdistrict = query

    clearTimeout(this.delayedTask)
    this.delayedTask = setTimeout(() => {
      this.fetchDistrictData()
    }, 600)
  }

  private clearAttachments() {
    this.file = new File([], '')
    this.isExcelFileValid = false
  }

  private onFileChange($event: File) {
    this.file = $event

    const reader = new FileReader()

    reader.readAsDataURL($event)
    reader.onloadend = () => {
      this.isExcelFileValid = true
      if (this.file.size > MAX_SIZE) {
        this.isExcelFileValid = false
      }
      if (!this.acceptedFileFormat.includes(this.file.type)) {
        this.isExcelFileValid = false
      }
    }

    reader.onerror = (evt) => {
      console.error(evt)
    }
  }

  private onSendExcelFile() {
    this.controller.bulkInsert(this.file)
  }

  private appendOrRemove(
    filterType: 'filterStatus' | 'filterSubdistrict' | 'filterSubscription',
    isRemove: boolean,
    value: string
  ) {
    switch (filterType) {
      case 'filterStatus':
        if (isRemove) {
          this.parameters.filterStatus = this.parameters.filterStatus.filter(
            (item) => item.value !== value
          )
        } else {
          this.parameters.filterStatus.push(
            this.statusOptions.find((item) => item.value === value) as Record<
              string,
              unknown
            >
          )
        }
        break

      case 'filterSubscription':
        if (isRemove) {
          this.parameters.filterSubscription = this.parameters.filterSubscription.filter(
            (item) => item.value !== value
          )
        } else {
          this.parameters.filterSubscription.push(
            this.subscriptionOptions.find(
              (item) => item.value === value
            ) as Record<string, unknown>
          )
        }
        break
    }

    this.fetchPOSData(true)
  }

  private commaToArray(value: string): string[] {
    return value ? value.split(',') : []
  }

  private arrayToComma(array: Record<string, unknown>[]): string {
    const arr = array.map((item) => item.value)
    return arr.join(',')
  }
}
